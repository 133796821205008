/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto&display=swap'); */
@font-face {
	font-family: 'Clip';
	src: local('Clip'), url('../Fonts/Clip.ttf') format('truetype');
}
*{
padding: 20;
margin: 0;
text-decoration: none;
list-style: none;
box-sizing: border-box;
}
.fpContainer {
width: 100%;
margin-right: auto;
margin-left: auto;
}
.mw-text-size{
	font-size: 18px;
}
.image3 {
    background-image: url('../Images/M3.png');
    background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: fixed;
	top: 40px;
	left: 10px;
    right:10px;
    bottom:30px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	min-width: 95%;
	min-height: 90%;
	border-radius: 30px;
}
.header2 h1{
	height: 85vh; /* Magic here */
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;font-weight: normal;
	font-family: Clip !important;
	color: white;
	font-size:12vw;
	text-shadow: 0 0 5px rgb(255, 103, 247), 0 0 10px rgb(255, 103, 247);
}
.header,#hometext {
	caret-color: transparent;
}
.support-text{
	margin-top: 4%;
	justify-content: center;
}
#Navitem2{
	display: none;
}