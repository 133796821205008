.tc-btn-primary {
  color: #fff;
  background-color: #3498db;
}
.input-group .btn {
  position: relative;
  z-index: 0;
}
.tc-card-text {
  text-align: center;
  margin-bottom: 0;
  margin-top: 12%;
  color: #fff;
  margin-left: -7%;
  font-size: 25px;
  background-color: #3498db;
  width: 114%;
  padding: 2%;
  border-radius: 0px 0px 30px 30px;
}
.body-tc{
  height: 828px;
  position: relative;
}
.tc-search-container
{
width: 20%;
margin-left: 65%;
}
.tc-card-title {
  font-size: 70px;
  font-weight: 400;
  text-align: center;
  margin-top: 10%;
}
.tc-card-container{
  display: inline-flex;
  width: 50%;
  margin-left: 25%;
  justify-content: stretch;
}
.tc-card {
  margin: 30px auto;
  width: 250px;
  height: 195px;
  border-radius: 40px;
  transition: 0.4s;
  border: 2px solid #3498db;
}
.Nodata{
  color: #ff4b2b;
  font-weight: 600;
  font-size: 18px;
}
.tc-table
{
  margin-top: 0%;
}
.container-body
{
  min-width: 1000px;
  min-height: 555px;
}
.active, .btn:hover {
  font-size: 15px;
  font-weight: 600;
  padding: 10%;
  text-decoration: underline;
}
.btn-pen2{
  cursor: pointer;
  border-radius: 10px 0px 0px 0px;
  font-weight: 500;
  background-color: #fff;
  color: #3498db;
}
.btn-com2{
  cursor: pointer;
  border-radius: 0px 10px 0px 0px;
  font-weight: 500;
  background-color: #fff;
  color: #3498db;
}
.btn-pen{
  cursor: pointer;
  border-radius: 10px 0px 0px 0px;
  font-weight: 500;
}
.btn-com{
  cursor: pointer;
  border-radius: 0px 10px 0px 0px;
  font-weight: 500;
}

/* .tcwall-head{
  display: flex;
  flex-direction: row;
  justify-content:space-evenly;
  border: 1px solid #fff;
  background-color: #3498db;
  width: 20%;
  color: #fff;
  font-weight: 500;
  margin-left: 15.75%;
  font-size: 13px;
  margin-bottom: -3.3%;
} */

.tcwalletfooter1
{
  margin-top: 0.3%;
}

.tcwalletfooter-social
{
  margin-bottom: 0.5%;
}
.tcwalletmodal
{
  display: flex;
  flex-direction: row;
}
.tick-img {
  position: absolute;
  left: 80%;
  top: 60%;
  width: 15%;
  cursor: pointer;
}
.tick-img2 {
  position: absolute;
  left: 55%;
  top: 60%;
  width: 15%;
  opacity: 10%;
}
.tcwalleth1 {
  text-align: center;
  font-size: 4vw;
  text-transform: uppercase;
  background: #fff;
  letter-spacing: 5px;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-repeat: no-repeat;
  background-size: 80%;
  position: relative;
  min-width: 95%;
  min-height: 90%;
  display: flex;
    justify-content: center;
    color: #fff;
    margin-top: -2.5%;
       
    font-family: 'Clip';
}
.tcwalletfooter #footer {
  position: relative;
  bottom: 0;
  height: 70px;
}
.edit-logo{
  width:100%;
}
.loadings
{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.table>:not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: #3498db;
}
.loading
{
width: 15%;
margin-left: 40%;
margin-top: 12%;
margin-bottom: 12%;
}
img.logo-logout {
  /* width: 80%;
  height: auto;
  max-width: 60px;
  z-index: 100;
  top: 3%;
  right: 2px;
  position: fixed; */
  width: 52px;
    height: auto;
    max-width: 60px;
    z-index: 100;
    top:4%;
    right: 2px;
    position: fixed;
    cursor: pointer;
}
  
/* Buttons  */
.buttons {
  padding: 0.5rem 1rem;
  border-radius: var(--main-border-radius);
  width: max-content;
  text-transform: capitalize;
  font-variant: small-caps;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}
  
 .button-main {
    text-align: center;
    cursor: pointer;
    font-size:15px;
    margin: 0 0 0 100px;
   
    z-index: 100;
    top: 5%;
    right: 2%;
    position: fixed;
 }
  #button-main{
    text-align: center;
    cursor: pointer;
    font-size: 15px;
    margin: 0 0 0 100px;
    color: #fff;
    z-index: 100;
    top: 5%;
    right: 9%;
    position: fixed;
  }
  /*Button Two*/
.button-main {
    border-radius: 4px;
    background-color:#fff;
    border: none;
    padding: 5px;
    width: 100px;
    transition: all 0.5s;
  
  }
  #button-main {
    border-radius: 4px;
    font-size: 20px;
    border: none;
    padding: 5px;
    padding-left: 5px;
    width: 100px;
    transition: all 0.5s;
  
  }
  
  .button-main span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }
  
  .button-main span:after {
    content: '»';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }
  .button-main:hover span {
    padding-right: 25px;
  }
  .button-main:hover span:after {
    opacity: 1;
    right: 0;
  }
  .updatebutton2 {
    background-color: #ff4b2b; /* Green */
    border: none;
    color: white;
    padding: 10px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 5px;
  }
  .updatebutton {
    
    border: none;
    
    padding: 15px 32px;
  
    border-radius: 5px;
  }
  /* .table>:not(caption)>*>* {
    
   border: 2px solid red;
    cursor:pointer;
    
} */
.fa-edit{
  font-size: 20px;
}
  table { 
	width: 750px; 
	border-collapse: collapse; 
	margin:50px auto;
	}

/* Zebra striping */
tr:nth-of-type(odd) { 
	background: #eee; 
	}

th { 
	background: #3498db; 
	color: white; 
	font-weight: bold; 
	}

th { 
	padding: 10px; 
	border: 1px solid #ccc; 
	text-align: center; 
	font-size: 12px;
	}
  td { 
    padding: 10px; 
    border: 1px solid #ccc; 
    text-align: center; 
    font-size: 14px;
    width: 20%;
    }

    .pagination_center
    {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: -5%;
    }
    .paginationBttns {
        width: 80%;
        height: 40px;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .paginationBttns a {
        padding: 10px;
        margin: 8px;
        border-radius: 10px;
        border: none;
        color: rgb(26, 26, 29);
        cursor: pointer;
        height: 10%;
        width: 10%;
        text-decoration: none;
    }
    .paginationActive {
        color: #FFB830;
        font-size: 190%;
    }
    @media (max-width: 968px)
    {
      .tc-search-container
{
width: 75%;
margin-left: 20%;
}

      .tc-card-container{
        display: flex;
        width: none;
        margin-left: 12%;
        flex-direction: column;
      }
      .body-tc{
        height: 0px;
        position: relative;
      }
      .container-body
      {
        min-width: 0px;
        min-height: 700px;
      }
      .tick-img {
        position: absolute;
    left: 80%;
    top: 60%;
    width: 15%;
    cursor: pointer;
    }    
     .tcwalleth1 {
      font-size: 6vw;
    }
      .button-main {
        text-align: center;
        cursor: pointer;
        font-size:15px;
        margin: 0 0 0 100px;
       
        z-index: 100;
        top: 6%;
        right: 2%;
        position: fixed;
     }
      #button-main{
        text-align: center;
        cursor: pointer;
        font-size: 15px;
        margin: 0 0 0 100px;
        color: #fff;
        z-index: 100;
        top: 1%;
        right: 2%;
        position: fixed;
      }
    }
  
.wallet-tableContainer
{
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px)
{
  .edit-logo{
    width: 70%;
  }
  .loading
  {
  width: 50%;
  margin-left: 25%;
  margin-top: 20%;
  }
  .wallet-tableContainer
{
  margin-top: 5%;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

    .pro-container3 {
        position: absolute;
        top: 20%;
        margin-left: 10%;
    }
    .paginationBttns {
        width: 80%;
        height: 40px;
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .pagination_center {
        width: 100%;
        margin-top: 15%;
        margin-left: 20%;
        margin-right: 0%;
        display: flex;
        justify-content: center;
    }
    .walletnav {
      list-style-type: none;
      margin: 0px;
      padding: 1%;
      overflow: hidden;
      background-color: rgb(38, 54, 77);
  }
  }
  .form-search{
    margin: 0 auto;
    text-align: center;
    font: bold 13px sans-serif;
    max-width:30%;
    position: relative;
    left: 30.5%;  
}

.form-search input{
    width: 60%;
    box-sizing: border-box;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    background-color:  #ffffff;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
    padding: 14px 15px 14px 40px;
    border: 1px solid #b6c3cd;;
    border-right: 0;
    color:#4E565C;
    outline: none;
    -webkit-appearance: none;
}

.form-search button{
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    background-color:  #3498db;
    box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.08);
    color: #ffffff;
    padding: 15px 22px;
    margin-left: -4px;
    cursor: pointer;
    border: none;
    outline: none;
}

.form-search i{
    position: absolute;
    top: 15px;
    left: 20px;
    font-size: 16px;
    color: #80A3BD;
}
#closeicon{
  position: absolute;
  left: 96%;
  top: 5%;
  color: grey;
  cursor: pointer;
}
.modal__backdrop {
  background: rgba(0, 0, 0, 0.45);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

  .modal__container {
    background: #fff;
    border-radius: 5px;
    max-width: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 290px;
  }
.trans {
  padding: 10px;
  border: 1px solid #000;
  width: 80%;
}
 #transbtn{
  position: fixed;
  left: 50%;
  top:85%;
  transform: translate(-50%, -50%);
  
}
.modal__title {
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 25px;
} 

.ul {
 
	list-style: none;
	margin: 0;
	padding-left: 0;
}

.li {
	color: #fff; 
	display: block;
	float: left;
	padding: 1rem;
	position: relative;
	text-decoration: none;
  transition-duration: 0.5s;
}
  
.li a {
  color: #000;
  text-decoration: none;
}

.li:hover {
 
	cursor: pointer;
}

.ul .li .ul {
	background: #fff;
	visibility: hidden;
  opacity: 0;
  min-width: 5rem;
	position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
	left: 0;
  display: none;
  border: 2px solid red;
}

.ul .li:hover > .ul,
.ul .li .ul:hover {
  visibility: visible;
  opacity: 1;
  display: block;
}

.ul .li .ul .li {
	clear: both;
  width: 100%;
}
.DropdownMenu{
  margin-top: -7%;
  position: fixed;
  right: 4%;
}